<script setup>
import { storeToRefs } from 'pinia';
import { useCenterStore } from '~/stores/centerDetails';

const props = defineProps({
    center: {
        type: Object,
        required: true
    }
});

// Get current center selection
const { centerRoute } = storeToRefs(useCenterStore());
const isSelected = computed(() => centerRoute.value === props?.center?.uid);
const getSelectedClass = computed(() => ({ 'center-list-item-selected': isSelected.value }))
</script>

<template>
    <li class="center-list-item" :class="getSelectedClass">
        <div class="center-list-item-thumbnail-container">
            <img
                v-if="center.logotype" :src="center.logotype.url"
                :alt="center.logotype.altText || center.name"
                width="116" height="68"
                class="center-list-item-thumbnail"
            >
        </div>
        <div class="center-list-item-content">
            <p class="center-list-item-headline">
                {{ center.name }}
            </p>
            <!--
            <address v-if="center.address?.plain" class="center-list-item-subheadline" :title="center.address.plain">
                {{ center.address.plain }}
            </address>-->
        </div>
    </li>
</template>

<style lang="scss">
.center-list-item {
    position: relative;
    display: grid;
    grid-template-columns: 145px 1fr;
    grid-template-areas: "thumbnail content";
    align-items: center;
    background-color: $coupon-bg;
    border-radius: px2rem(24);
    height: px2rem(100);
    margin-bottom: 15px;
    cursor: pointer;
    transition: box-shadow 400ms ease;

    &.center-list-item-selected,
    &:hover {
        box-shadow: 4px 4px 5px $account-overview-item-shadow1, 2.5px 2.5px 0 $account-overview-item-shadow2;
    }

    &.center-list-item-selected {
        cursor: default;
    }
}

.center-list-item-thumbnail-container {
    grid-area: thumbnail;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    isolation: isolate;
    padding: 15px;
}

.center-list-item-thumbnail {
    height: 68px;
    width: 116px;
    max-height: 80%;
    max-width: 80%;
    object-fit: contain;
}

.center-list-item-content {
    grid-area: content;
    overflow: hidden;
    padding: 0 15px 0 0;
}

.center-list-item-headline {
    @include body2-extrabold;

    margin: 0;
}

.center-list-item-subheadline {
    @include body2-medium;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    margin: 0;
}
</style>
